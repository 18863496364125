@font-face {
  font-family: 'Silka';
  src: local('silka-regular-webfont'), url('./fonts/silka-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Silka';
  src: local('silka-bold-webfont'), url('./fonts/silka-bold-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Silka';
  src: local('silka-light-webfont'), url('./fonts/silka-light-webfont.ttf') format('truetype');
  font-weight: 300; /* Light variant */
  font-style: normal;
}

@font-face {
  font-family: 'Silka';
  src: local('silka-medium-webfont'), url('./fonts/silka-medium-webfont.ttf') format('truetype');
  font-weight: 500; /* Medium variant */
  font-style: normal;
}

body {
  background-color: #000; /* Set a background color as a fallback */
  background-image: url('https://uploads-ssl.webflow.com/642ed5577f370f43c0fabd66/65afb5a16d2e9c58924fdc73_Bg-embassy%20(1).jpg'); /* Replace with the path to your image */
  background-size: cover; /* Cover the entire viewport */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
}

h1, h2, h4, h5, h6 {
  color: #fff;
  font-family: 'Silka';
  font-weight: bold;

}

p {
  color: #c6c6c6;
  font-family: 'Silka';
  font-weight: normal;
}

label {
  color: #c6c6c6;
  font-family: 'Silka';
  font-weight: normal;
  font-size: 15px;
  margin-right: 10px;
}

/* Button Styling */
.confirmButton {
  color: #000;
  font-family: 'Silka';
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  border-radius: 100px;
  border: solid 1.5px white;
  background-color:#fff;
  padding: 15px 35px;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.confirmButton:hover {
  background-color: transparent;
  color: white;
}

.confirmButton:disabled {
  opacity: 0.25; /* Opacity for the disabled state */
  background-color: transparent; /* Remove white background */
  color: #fff;
}
.hero-h1 {
  margin-bottom: 50px;
  font-size: 3rem;
  text-align: center;
}

.hero-h2 {
  font-size: 3rem;
  margin-bottom: 15px;
}

.logo-top {
  position: relative;
margin-top: 15px;
}

.logo-bot {
  margin-bottom: 15px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: space-between;
}

/* DIV STYLING */

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sec-content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  max-width: 90%;
  background-color: #00000071;
  padding: 25px 25px;
  border-radius: 5px;
  margin-bottom: 25px;
}

.calendar-wrapper {
  flex: 1;
  margin-right: 20px;
}

.zero-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.zero-top h3 {
  margin-block-start: 0em;
  margin-block-end: 0.5em;
  color: #fff;
  font-family: 'Silka';
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
}

.zero-top h2 {
  margin-block-start: 0em;
  margin-block-end: 0.5em;
  color: #fff;
  font-family: 'Silka';
  font-weight: bold;
  text-transform: uppercase;
}

.zero-top p {
  margin-block-start: 0em;
  margin-block-end: 1em;
  color: #fff;
  font-family: 'Silka';
  font-weight: 500  ;
  font-size: 15px;
}

.gecko {
  font-family: Silka;
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  width: 98%;
  text-align: right;
}

a {
  color: #c6c6c6;
}

.box { 
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 35px;
  background-color: #00000071;
  margin-top: 25px;
  border-radius: 10px;
  border: 0.5px solid #ffffff22;
}

.center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* CheckBox Styling */

input[type="checkbox"] {
  transform: scale(1);
  margin-right: 5px;
  margin-bottom: -3px;
  appearance: none;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 1px;
  padding: 7px;
  cursor:pointer;
}

input[type="checkbox"]:checked::before {
  content: '\2713';
  font-size: 14px;
  color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[type="checkbox"]:checked {
  background-color: #fff;
}

/* Radio Styling */
input[type="radio"] {
  transform: scale(1); /* Make the radio button 25% bigger */
  margin-right: 5px;
  cursor: pointer;
  margin-bottom: -3px;
  appearance: none; /* Remove default styles */
  background-color: #000; /* Set background color */
  border: 1px solid #fff; /* Add a border for visibility */
  border-radius: 50%; /* Make the border a circle for radio buttons */
  padding: 7px; /* Adjust padding for better visual appearance */
}

label input:disabled {
  opacity: 0.25;
}


/* Optional: Style the radio button when checked */
input[type="radio"]:checked {
  background-color: #fff; /* Set a different background color when checked */
}

label input[type="radio"]:disabled + span.disabled-text {
  opacity: 0.25;
}

.contact-container {
  position: relative;
}

.contact-label {
  display: block;
  margin-bottom: 5px;
  color: white;
  font-family: 'Silka', sans-serif;
}

.contact-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #fffff01b !important;
  border-radius: 2px;
  background-color: #1b1b1b;
  color: white;
  font-family: 'Silka', sans-serif;
}

.contact-input::placeholder {
  color: #c6c6c6;
  font-size: 14px;
}

.contact-input:focus {
  outline: none;
  border-color: #fff !important;
}

.email-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #fffff01b !important;
  border-radius: 2px;
  background-color: #1b1b1b;
  color: white;
  font-family: 'Silka', sans-serif;
}

.email-input::placeholder {
  color: #c6c6c6;
  font-size: 14px;
}

.email-input:focus {
  outline: none;
  border-color: #fff !important;
}

/* Error message */
.error-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.error-popup {
  background-color: black;
  color: #c6c6c6;
  padding: 50px;
  border-radius: 5px;
  box-shadow: 2 4px 6px rgba(0, 0, 0, 0.5);
  border: 1px solid #6b6b6b;
  max-width: 300px;
  text-align: center;
  font-family: 'Silka', sans-serif;
  font-weight: normal;
  font-size: 14px;
}

.error-popup h3 {
  margin-bottom: 0px;
  font-size: 20px;
  color: white;
}

.error-popup button {
  margin-top: 20px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px 20px;
  border-radius: 100px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Silka', sans-serif;
  font-weight: bold;
  border: 1px solid #fff;
}

.error-popup button:hover {
  background-color: #000000;
  color: #fff;
  border: 1px solid #fff;

}

/* Calendar */

.c9::before {
  background-color: transparent !important;
}

/* Email Input Styling */
.email-input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #1b1b1b;
  color: white;
  font-family: 'Silka', sans-serif;
  box-sizing: border-box;
  transition: border-color 0.3s;
  margin-top: 15px;
}

.email-input::placeholder {
  color: #c6c6c6;
}

.email-input:focus {
  outline: none;
  border-color: #4caf50; /* Focus color */
}

/* Styling for invalid email */
.email-input.invalid {
  border-color: red; /* Color for invalid input */
}

.error-message {
  color: rgb(221, 54, 54) !Important;
  font-family: 'Silka', sans-serif;
  font-size: 12px !important;
  padding-top: 10px;
  font-weight: 300 !important;
}

.error-message- {
  color: rgb(221, 54, 54) !Important;
  font-family: 'Silka', sans-serif;
  font-size: 12px !important;
  padding-top: 10px;
  font-weight: 300 !important;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.error-message-server {
  color: rgb(221, 54, 54) !Important;
  font-family: 'Silka', sans-serif;
  font-size: 16px !important;
  padding-top: 0px;
  font-weight: 300 !important;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.price-box {
  display: flex;
  flex-direction: row;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rdrDefinedRangesWrapper { display: none; }
.rdrInputRanges { display: none; }

.tile-green { background-color: lightgreen; }
.tile-yellow { background-color: lightyellow; }
.tile-red { background-color: lightcoral; }

.contact-label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #fff;
}

.contact-input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.contact-input:focus {
  outline: none;
  border-color: #4caf50; /* Change to your preferred focus color */
}

.asset-selection-container {
  margin-top: 10px;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
}

.spacer {
  margin-top: 10px;
}

.element {
  margin-bottom: 20px;
}

/* Mobile screens */

@media only screen and (max-width: 600px) {
  
  .hero-h1 {
    font-size: 2rem;
    line-height: 100%;
    max-width: 90%;
  }

  .price-box {
    display: flex;
    flex-direction: column;
  }

  .hero-h2 {
    font-size: 2rem;
    line-height: 100%;
    max-width: 90%;
  }
  
  .element {
    margin-bottom: 20px;
  }

  .sec-content {
    flex-direction: column;
  }

  .logo-top {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 0px;
  }

  .embassy-logo {
    width: 100px !important;
    margin-top: 15px;
  }

  .novel-logo {
    width: 30px;
    margin-bottom: 15px;
  }

  .gecko {
    position: relative;
    width: 100%;
    text-align: center;
  }

  .hero-h1 {
    margin-bottom: 25px;
    text-align: center;
  }

  .box {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .sec-content {
    margin-bottom: 10px;
  }
  
  .logo-bot {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .zero-top p {
    text-align: center;
  }
}